import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";
// using these style defenitions for the conversation close component
export const formStyles: BoxStyleProps = {
    padding: "space50",
    paddingTop: "space200",
    overflow: "auto",
    marginTop: "space200"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize30",
    marginBottom: "space30"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space70"
};
