import { SendEvent, SendFormEvent } from "./events";

export type InputFieldType = 'text' | 'number' | 'email';

export type InputFields = {
  name: string;
  label: string;
  placeholder: string;
  type: InputFieldType;
};

export type FormFields = InputFields[]

export type FormData = {
  data: { [key : string]: string };
};

export interface EventData {
  eventName: string;
}

export interface FormEventData extends EventData {
  formData: FormData;
}

export const isFormEvent = (event: SendEvent<EventData>): event is SendFormEvent => {
  return !!(event.detail as FormEventData).formData
}