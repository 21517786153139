import {LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { PopupMessageEvent } from '../events';

@customElement('cresta-popup-message-dispatcher')
export class CrestaPopupMessageDispatcher extends LitElement {

  @property({type: String, attribute: 'message'}) message: any;

  override firstUpdated(){
    this.dispatchEvent(new PopupMessageEvent(this.message));
  }
}
