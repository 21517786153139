import * as React from 'react';
import {createComponent} from '@lit-labs/react';
import {
  CrestaMessageRenderer as WCComponent,
  CrestaText as WCTextComponent,
  CrestaPopup as WCPopup,
  CrestaLightbox as WCLightbox,
  SendMessageEvent,
  SendEvent,
  SetParameterEvent,
  PopupMessageEvent,
  SendImageSourceEvent,
} from '@cresta/bot-kit-core';

import type {EventName} from '@lit-labs/react';

export const CrestaMessageRenderer = createComponent(
  React,
  'cresta-message-renderer',
  WCComponent,
  {
    onSendMessage: SendMessageEvent.eventName as EventName<SendMessageEvent>,
    onSendEvent: SendEvent.eventName as EventName<SendEvent>,
    onSetParameter: SetParameterEvent.eventName as EventName<SetParameterEvent>,
  }
);

export const CrestaPopup = createComponent(React, 'cresta-popup', WCPopup, {
  onPopupMessage: PopupMessageEvent.eventName as EventName<PopupMessageEvent>,
});

export const CrestaLightbox = createComponent(
  React,
  'cresta-lightbox',
  WCLightbox,
  {
    onSetImageSource:
      SendImageSourceEvent.eventName as EventName<SendImageSourceEvent>,
  }
);

export const CrestaText = createComponent(
  React,
  'cresta-text',
  WCTextComponent
);

export {
  SendMessageEvent,
  SendEvent,
  SetParameterEvent,
  PopupMessageEvent,
  SendImageSourceEvent,
};
