import {DDKeys as Keys} from '../dd-keys';
import {BOTKIT_VERSION} from '../version';

const enum LogType {
  Error = 'error',
  Info = 'info',
}

export default class DDLogger {
  static existingLogger: DDLogger | null = null;
  public static getInstance(hostName: string = '', botName: string = '') {
    if (this.existingLogger) {
      this.existingLogger.hostName = hostName || this.existingLogger.hostName;
      this.existingLogger.hostName = botName || this.existingLogger.botName;
      return this.existingLogger;
    }
    this.existingLogger = new DDLogger(hostName, botName);
    return this.existingLogger;
  }
  private constructor(
    public hostName: string = '',
    public botName: string = ''
  ) {}
  public info = (message: string) => this.sendLog(message);
  public error = (exception: Error) =>
    this.sendLog(undefined, LogType.Error, exception);
  private async sendLog(
    message?: string,
    logType: LogType = LogType.Info,
    exception?: Error
  ) {
    if (!message && !exception) return;
    const body = [
      {
        ddsource: 'bot-kit',
        ddtags: BOTKIT_VERSION,
        status: logType,
        hostname: this.hostName || window.location.host,
        message: message || exception?.message || '',
        service: this.botName,
        botKitVersion: BOTKIT_VERSION,
        botPageUrl: window.location.href,
        ...((this.botName && {botName: this.botName}) || {}),
        ...((this.hostName && {clientName: this.hostName}) || {}),
        ...((exception?.message && {exception: exception?.message}) || {}),
        ...((exception?.stack && {stackTrace: exception?.stack}) || {}),
      },
    ];
    try {
      const data = await fetch(
        `https://http-intake.logs.datadoghq.com/api/v2/logs?dd-api-key=${Keys.apiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      );
      console.info(
        'Datadog called successfully. Returned data: ' + JSON.stringify(data)
      );
    } catch (e) {
      console.error('Datadog log failed. Error: ' + JSON.stringify(e));
    }
  }
}
