import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';

/**
 * Cresta Video Widget
 *
 * @slot - message content which was received from Cresta
 */
@customElement('cresta-video-widget')
export class CrestaVideoWidget extends LitElement {
  static override styles = css`
    video {
      max-width: 300px;
      max-height: 300px;
    }
  `;

  /**
   * Video Src
   */
  @property({type: String})
  src = '';
  override render() {
    return html`<video src="${this.src}" controls>
      Your browser does not support the video tag.
    </video>`;
  }

  override attributeChangedCallback(
    name: string,
    oldVal: string,
    newVal: string
  ) {
    super.attributeChangedCallback(name, oldVal, newVal);
    this.requestUpdate();
  }
}
