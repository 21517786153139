import { custom } from '@twilio/declarative-type-validator';

// Any JSON value
export const attributesValidator = custom((value) => [
    ['string', 'number', 'boolean', 'object'].includes(typeof value),
    'a JSON type'
]);

export const optionalAttributesValidator = custom((value) => [
    ['undefined', 'string', 'number', 'boolean', 'object'].includes(typeof value),
    'an optional JSON type'
]);
