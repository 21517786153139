import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { SendImageSourceEvent } from '../events';

@customElement('cresta-image-widget')
export class CrestaImageWidget extends LitElement {
  static override styles = css`
    #myImg {
      max-width: 300px;
      width: 100;
      height: auto;
      border: 10px solid #020c5d;
      border-radius: 10px 10px 10px 3px;
      cursor: pointer;
      transition: 0.3s;
    }

    #myImg:hover {
      opacity: 0.7;
    }
  `;

  /**
   * Image Src
   */
  @property({type: String})
  src = '';

  override render() {
    return html`
      <img id="myImg" @click=${this.imageClicked} src=${this.src} />
    `;
  }

  imageClicked(){
    this.dispatchEvent(new SendImageSourceEvent(this.src));
  }

  override attributeChangedCallback(
    name: string,
    oldVal: string,
    newVal: string
  ) {
    super.attributeChangedCallback(name, oldVal, newVal);
    this.requestUpdate();
  }
}
