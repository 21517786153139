import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {
  SendMessageEvent,
  SendEvent,
  SetParameterEvent,
  ButtonClickedEvent,
} from '../events';

@customElement('cresta-button')
export class CrestaButton extends LitElement {
  @property({type: String, attribute: 'text'}) text = '';
  @property({type: String, attribute: 'event'}) event = '';
  @property({type: String, attribute: 'link'}) link = '';
  @property({type: String, attribute: 'parameter'}) parameter = '';

  static override styles = css`
    button {
      border: 1px solid var(--border-color, #020c5d);
      background-color: var(--background-color, white);
      color: var(--text-color, #020c5d);
      padding: 8px 8px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-right: 4px;
      font-size: 10pt;
      font-family: inherit;
    }
  `;

  override render() {
    if (this.link) {
      return html`<a href="${this.link}" target="_blank"
        ><button><u>${this.text}</u></button></a
      >`;
    }
    return html`<button @click=${this.buttonClicked}>${this.text}</button>`;
  }

  buttonClicked() {
    this.dispatchEvent(new ButtonClickedEvent());

    if (!this.event) {
      this.dispatchEvent(new SendMessageEvent(this.text));
    } else {
      this.dispatchEvent(new SendEvent({eventName: this.event}));
    }

    if (this.parameter) {
      this.dispatchEvent(new SetParameterEvent(this.parameter));
    }
  }
}
