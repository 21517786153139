import { MediaCategory } from '@twilio/mcs-client';
import { parseToNumber } from './util';
import { SendEmailOptions, SendMediaOptions } from './conversation';

/**
 * An unsent message. Returned from {@link MessageBuilder.build}.
 */
class UnsentMessage {
  public text?: string;
  public attributes: any = {};
  public mediaContent: [MediaCategory, FormData | SendMediaOptions][] = [];
  public emailOptions?: SendEmailOptions = {};

  /**
   * @internal
   */
  constructor(private messagesEntity: any) {}

  /**
   * Send the prepared message to the conversation.
   * @returns Index of the new message in the conversation.
   */
  async send(): Promise<number> {
    const response = await this.messagesEntity.sendV2(this);
    return parseToNumber(response.index);
  }
}

export { UnsentMessage };
