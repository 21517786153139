import {LitElement, html, css} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import { PopupMessageEvent } from '../events';

@customElement('cresta-popup')
export class CrestaPopup extends LitElement {
  constructor() {
    super();
    window.addEventListener(PopupMessageEvent.eventName, (e: Event) => {
      this.message = (e as PopupMessageEvent).detail.message;
    });
  }

  static override styles = css`
    .cresta-popup {
      width: 250px;
      text-align: right;
      position: absolute;
      bottom: var(--from-bottom, 90px);
      right: var(--from-right, 90px);
    }
    .cresta-popup-message {
      background-color: var(--message-background-color, #020c5d);
      border-radius: 8px;
      width: fit-content;
      padding: 8px;
      margin: 0px;
      color: var(--message-text-color, #ffffff);
      text-align: left;
      font-size: 14pt;
      float: right;
    }

    .cresta-popup-message:hover {
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
    }

    .close {
      visibility: hidden;
    }

    .cresta-popup:hover .close {
      visibility: visible;
      vertical-align: middle;
      border: none;
      color: inherit;
      border-radius: 50%;
      background: transparent;
      position: relative;
      width: 25px;
      height: 25px;
      opacity: 0.6;
      opacity: 1;
      background: #878ba5;
    }

    /* tines of the X */
    .close::before,
    .close::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 13px;
      width: 1.4px;
      background-color: currentColor;
      color: white;
    }
    .close::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    .close::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `;

  @property()
  private message: string | undefined;

  @state()
  private _hidden = false;

  hideMessage() {
    this._hidden = true;
  }

  override render() {
    if (!this._hidden && this.message)
      return html` <div class="cresta-popup">
        <div style="margin-bottom: 10px">
          <button
            @click=${this.hideMessage}
            class="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="cresta-popup-message">${this.message}</div>
      </div>`;

    return html`<div style="display:'none'"></div>`;
  }
}
