import {LitElement, html, css} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {SendFormEvent} from '../events';
import {FormFields, InputFields} from '../types';

@customElement('cresta-form')
export class CrestaForm extends LitElement {
  static override styles = css`
    .submit-button {
      border: 1px solid var(--border-color, #41a5f5);
      background-color: var(--background-color, white);
      color: var(--text-color, #41a5f5);
      padding: 8px 8px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-right: 4px;
      font-size: 10pt;
      font-family: inherit;
    }

    .submitted-button {
      border: 1px solid var(--border-color, #41a5f5);
      background-color: var(--background-color, #41a5f5);
      color: var(--text-color, white);
      padding: 8px 8px;
      font-size: 16px;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-right: 4px;
      font-size: 10pt;
      font-family: inherit;
    }

    .input-label {
      font-size: 10pt;
      font-family: inherit;
      margin-bottom: 4px;
      margin-right: 4px;
      margin-top: 0px;
    }

    .input-field {
      border: 1px solid var(--border-color, #e0e0e0);
      background-color: var(--background-color, white);
      color: var(--text-color, black);
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 8px 8px 8px 8px;
      font-family: inherit;
      font-size: 10pt;
      width: 70%;
      max-width: 300px;
      font-size: 10pt;
    }
  `;

  @state() private formSubmitted = false;

  @property({type: Array}) fields: FormFields = [];

  @property({type: String, attribute: 'event'}) event = '';

  private formData: {[key: string]: string} = {};

  handleInput(e: InputEvent) {
    const target = e.target as HTMLInputElement;
    this.formData[target.name] = target.value;
  }

  handleSubmit(e: Event) {
    e.preventDefault();
    this.dispatchEvent(
      new SendFormEvent({
        eventName: this.event,
        formData: {data: this.formData},
      })
    );
    this.formSubmitted = true;
  }

  getHtml = (inputFieldsObject: InputFields) => {
    return html` <div>
      <div><p class="input-label">${inputFieldsObject.label}</p></div>
      <div>
        <input
          type=${inputFieldsObject.type}
          @change=${this.handleInput}
          name=${inputFieldsObject.name}
          placeholder=${inputFieldsObject.placeholder}
          class="input-field"
          required
        />
      </div>
    </div>`;
  };

  override render() {
    if (!this.formSubmitted) {
      return html` <form @submit=${this.handleSubmit}>
        ${this.fields.map((object) => this.getHtml(object))}
        <div><input style="margin: 0px" class="submit-button" type="submit" /></div>
      </form>`;
    }

    return html`<div>
      <button style="margin: 0px" class="submitted-button">Form Submitted</button>
    </div>`;
  }

  override attributeChangedCallback(
    name: string,
    oldVal: string,
    newVal: string
  ) {
    super.attributeChangedCallback(name, oldVal, newVal);
    this.requestUpdate();
  }
}
