import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { Button } from "@twilio-paste/core/button";
import { useDispatch } from "react-redux";

import { containerStyles, titleStyles } from "./styles/Header.styles";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const dispatch = useDispatch();
    const ComponentName = () => <CloseIcon {...titleStyles} decorative={false} title="Description of icon" />;

    const HandleClose = () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.CloseConversation }));
    };

    return (
        <Box as="header" {...containerStyles}>
            <Text as="h2" {...titleStyles}>
                {customTitle || "Live Chat"}
            </Text>
            <Button variant="secondary_icon" onClick={HandleClose}>
                {ComponentName()}
            </Button>
        </Box>
    );
};
