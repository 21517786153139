import {LitElement, html, css} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import renderComponent, {isComponentData} from '../component-factory';
import DDLogger from '../utils/dd-logger';

/**
 * Cresta Message Renderer
 *
 * @slot - message content which was received from Cresta
 */
@customElement('cresta-message-renderer')
export class CrestaMessageRenderer extends LitElement {
  static override styles = css`
    :host {
      background-color: var(--background-color);
      margin-top: 8px;
    }

    cresta-button {
      --text-color: var(--button-text-color);
      --border-color: var(--button-border-color);
      --background-color: var(--button-background-color);
    }
  `;

  /**
   * Message Content
   */
  @property({type: Object, attribute: 'message'}) message: any;
  @state()
  private _hidden = false;

  buttonClicked() {
    this._hidden = true;
  }

  renderChildren() {
    const HTMLObjects: any = [];
    DDLogger.getInstance().info(this.message);
    try {
      if (this.message) {
        this.message.richContent.forEach((innerArray: any) => {
          innerArray.forEach((object: any) => {
            if (isComponentData(object)) {
              HTMLObjects.push(
                renderComponent(object, {buttonClicked: this.buttonClicked})
              );
            }
          });
        });

        return html`${HTMLObjects.map((object: any) => object)}`;
      }
    } catch (e) {
      DDLogger.getInstance().error(e as Error);
    }
    return null;
  }

  override render() {
    if (!this._hidden && this.renderChildren()) {
      return this.renderChildren();
    }

    return html`
      <div style="display:${this.message === null ? 'none' : 'block'}">
        <slot></slot>
      </div>
    `;
  }
}
