import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {marked} from 'marked';

@customElement('cresta-text')
export class CrestaText extends LitElement {
  /**
   * Text Content
   */
   @property({type: String, attribute: 'text'}) text: any;

   override render() {
    const renderer = new marked.Renderer();

    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" ');
    };

    renderer.paragraph = (text: string) => {
      return `<p style="margin: 0; padding: 0;">${text}</p>\n`;
    }

    marked.setOptions({
      renderer
    });

    const markdownText = marked.parse(this.text || '')
    const parser = new DOMParser();
    const markdownToHTMLDocument = parser.parseFromString(
      markdownText,
      'text/html'
    );
    const markdownHTML = markdownToHTMLDocument.body.firstChild;
    return html`${markdownHTML}`;
  }
  override attributeChangedCallback(name: string, oldVal: string, newVal: string) {
    super.attributeChangedCallback(name, oldVal, newVal)
      this.requestUpdate();
    }
}
