/**
 * Push notification type.
 */
type PushNotificationType =
  | 'twilio.conversations.new_message'
  | 'twilio.conversations.added_to_conversation'
  | 'twilio.conversations.removed_from_conversation';

interface PushNotificationDescriptor {
  title: string;
  body: string;
  sound: string;
  badge: number;
  action: string;
  type: PushNotificationType;
  data: Object;
}

/**
 * Additional data for a given push notification.
 */
interface PushNotificationData {
  /**
   * SID of the conversation.
   */
  conversationSid?: string;

  /**
   * Index of the message in the conversation.
   */
  messageIndex?: number;

  /**
   * SID of the message in the conversation.
   */
  messageSid?: string;
}

/**
 * Push notification for a Conversations client.
 */
class PushNotification {
  /**
   * Title of the notification.
   */
  public readonly title: string;

  /**
   * Text of the notification.
   */
  public readonly body: string;

  /**
   * Sound of the notification.
   */
  public readonly sound: string;

  /**
   * Number of the badge.
   */
  public readonly badge: number;

  /**
   * Notification action (`click_action` in FCM terms and `category` in APN terms).
   */
  public readonly action: string;

  /**
   * Type of the notification.
   */
  public readonly type: PushNotificationType;

  /**
   * Additional data of the conversation.
   */
  public readonly data: PushNotificationData;

  /**
   * @internal
   */
  constructor(data: PushNotificationDescriptor) {
    this.title = data.title || null;
    this.body = data.body || null;
    this.sound = data.sound || null;
    this.badge = data.badge || null;
    this.action = data.action || null;
    this.type = data.type || null;
    this.data = data.data || {};
  }
}

export {
  PushNotification,
  PushNotificationType,
  PushNotificationDescriptor,
  PushNotificationData
};
