import { EventData, FormEventData } from "./types";

export class SendMessageEvent extends CustomEvent<{text: string}> {
    static eventName = 'cresta-send-message';

    constructor(message: string){
        super(SendMessageEvent.eventName, {bubbles: true, composed: true, detail: {text: message}});
    }
}

export class SendEvent<T extends EventData = EventData> extends CustomEvent<T> {
    static eventName = 'cresta-send-event';

    constructor(eventData: T){
        super(SendEvent.eventName, {bubbles: true, composed: true, detail: eventData});
    }
}

export class SetParameterEvent extends CustomEvent<{parameter: string}> {
    static eventName = 'cresta-set-parameter'

    constructor(message: string){
        super(SetParameterEvent.eventName, {bubbles: true, composed: true, detail: {parameter: message}});
    }
}

export class ButtonClickedEvent extends CustomEvent<{}> {
    static eventName = 'cresta-button-clicked'

    constructor(){
        super(ButtonClickedEvent.eventName, {bubbles: true, composed: false});
    }
}

export class PopupMessageEvent extends CustomEvent<{message: string}> {
    static eventName = 'cresta-popup-message';
    constructor(message:string){
        super(PopupMessageEvent.eventName, {bubbles: true, composed: true, detail: {message: message}});
    }
}

export class SendImageSourceEvent extends CustomEvent<{src: string}> {
    static eventName = 'cresta-image-event';
    
    constructor(src:string){
        super(SendImageSourceEvent.eventName, {bubbles: true, composed: true, detail: {src: src}});
    }
}

export class SendFormEvent extends SendEvent<FormEventData> {
    constructor(formEventData: FormEventData){
        super(formEventData);
    }
}