import {html} from 'lit/static-html.js';
import {ComponentType} from './component-types';
import {UnknownPayloadTypeError} from './errors';
import {FormFields} from './types';

export interface ComponentData {
  type: ComponentType;
}

export interface VideoComponentData extends ComponentData {
  type: ComponentType.VIDEO;
  src: string;
}

export interface AudioComponentData extends ComponentData {
  type: ComponentType.AUDIO;
  src: string;
}

export interface ImageComponentData extends ComponentData {
  type: ComponentType.IMAGE;
  src: string;
}

export interface ButtonComponentData extends ComponentData {
  type: ComponentType.BUTTON;
  link?: string;
  text: string;
  event?: {
    name: string;
  };
  parameter?: string;
}

export interface PopupComponentData extends ComponentData {
  type: ComponentType.POPUP;
  text: string;
}

export interface FormComponentData extends ComponentData {
  type: ComponentType.FORM;
  fields: FormFields;
  event: {
    name: string;
  };
}

export interface ButtonComponentEvents {
  buttonClicked?: () => void;
}

type AllComponentData =
  | VideoComponentData
  | AudioComponentData
  | ImageComponentData
  | ButtonComponentData
  | PopupComponentData
  | FormComponentData;

type AllComponentEvent = ButtonComponentEvents;

export const isComponentData = (data: any): data is AllComponentData => {
  return 'type' in data;
};

const renderComponent = (data: AllComponentData, event: AllComponentEvent) => {
  switch (data.type) {
    case ComponentType.VIDEO:
      return html`<cresta-video-widget
        src="${data.src}"
      ></cresta-video-widget>`;
    case ComponentType.IMAGE:
      return html`<cresta-image-widget
        src="${data.src}"
      ></cresta-image-widget>`;
    case ComponentType.BUTTON:
      return html` 
      <cresta-button
        link=${data.link ? data.link : ''}
        text=${data.text}
        event=${data.event?.name ? data.event?.name : ''}
        parameter=${data.parameter || ''}
        @cresta-button-clicked=${event.buttonClicked}
      ></cresta-button>`;
    case ComponentType.POPUP:
      return html`<cresta-popup-message-dispatcher
        message=${data.text}
      ></cresta-popup-message-dispatcher>`;
    case ComponentType.FORM:
      return html`<cresta-form .fields=${data.fields} event=${data.event.name}></cresta-form>`;
    default:
      throw new UnknownPayloadTypeError(data.type);
  }
};

export default renderComponent;
