import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@twilio-paste/core";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { ChevronRightIcon } from "@twilio-paste/icons/esm/ChevronRightIcon";

import { changeEngagementPhase } from "../store/actions/genericActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { formStyles, introStyles, titleStyles } from "./styles/PreEngagementFormPhase.styles";
import { Header } from "./Header";
import { sessionDataHandler } from "../sessionDataHandler";

export const CloseConversationPhase = () => {
    const { chat } = useSelector((state: AppState) => ({
        chat: state.chat
    }));

    const dispatch = useDispatch();

    const handleClose = async () => {
        if (chat.conversationState === "active") await sessionDataHandler.closeAndRemoveSession();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
    };

    const handleContinue = () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
    };

    return (
        <>
            <Header />
            <Box {...formStyles}>
                <Text {...titleStyles} as="h2">
                    Are you sure you want to close this chat?
                </Text>
                <Text {...introStyles} as="p">
                    Unfortunately, the chat history will be lost.
                </Text>
                <Stack orientation="horizontal" spacing="space60">
                    <Button variant="primary" onClick={handleClose}>
                        <CloseIcon decorative={false} title="Description of icon" />
                        Close chat
                    </Button>
                    <Button variant="secondary" onClick={handleContinue}>
                        <ChevronRightIcon decorative={false} title="Description of icon" />
                        Continue chat
                    </Button>
                </Stack>
            </Box>
        </>
    );
};
