import { DDKeys as Keys } from "../dd-keys";

const enum LogType {
    Error = "error",
    Info = "info"
}

const VERSION: string | undefined = process.env.REACT_APP_VERSION;

// eslint-disable-next-line import/no-unused-modules
export default class DDLogger {
    static exsitingLogger: DDLogger | null = null;

    public hostName: string = "";

    public botName: string = "";

    private constructor(hostName: string = "", botName: string = "") {
        this.hostName = hostName;
        this.botName = botName;
    }

    public static getInstance(hostName: string = "", botName: string = "") {
        if (this.exsitingLogger) {
            this.exsitingLogger.hostName = hostName || this.exsitingLogger.hostName;
            this.exsitingLogger.botName = botName || this.exsitingLogger.botName;
            return this.exsitingLogger;
        }
        this.exsitingLogger = new DDLogger(hostName, botName);
        return this.exsitingLogger;
    }

    public info = async (message: string) => this.sendLog(message);

    public error = async (exception: Error) => this.sendLog(undefined, LogType.Error, exception);

    private async sendLog(message?: string, logType: LogType = LogType.Info, exception?: Error) {
        if (!message && !exception) return;
        const body = [
            {
                ddsource: "twilio-flex-widget",
                ddtags: VERSION,
                status: logType,
                hostname: this.hostName || window.location.host,
                message: message || exception?.message || "",
                service: this.botName,
                flexWidgetVersion: VERSION,
                botPageUrl: window.location.href,
                ...((this.botName && { botName: this.botName }) || {}),
                ...((this.hostName && { clientName: this.hostName }) || {}),
                ...((exception?.message && { exception: exception?.message }) || {}),
                ...((exception?.stack && { stackTrace: exception?.stack }) || {})
            }
        ];
        try {
            const data = await fetch(`https://http-intake.logs.datadoghq.com/api/v2/logs?dd-api-key=${Keys.apiKey}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
            // eslint-disable-next-line no-console
            console.info(`Datadog called successfully. Returned data: ${JSON.stringify(data)}`);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Datadog log failed. Error: ${JSON.stringify(e)}`);
        }
    }
}
