import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch, useSelector } from "react-redux";

import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles } from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    const fetchSession = async () => {
        const tokens = await sessionDataHandler.fetchAndStoreNewSession({
            formData: {
                query: "hi"
            }
        });
        dispatch(initSession({ token: tokens.token, conversationSid: tokens.conversationSid }));
    };

    const handleEntryPointClick = () => {
        // Initialize the session when the widget is being expanded.
        if (!expanded) {
            const data = sessionDataHandler.tryResumeExistingSession();
            if (data) {
                try {
                    dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
                } catch (e) {
                    fetchSession();
                }
            } else {
                fetchSession();
            }
        }
        dispatch(changeExpandedStatus({ expanded: !expanded }));
    };

    return (
        <Box as="button" data-test="entry-point-button" onClick={handleEntryPointClick} {...containerStyles}>
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            ) : (
                <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
            )}
        </Box>
    );
};
