/**
 * Signifies the amount of participants which have the status for the message.
 */
type DeliveryAmount = 'none' | 'some' | 'all';

interface AggregatedDeliveryDescriptor {
  total: number;
  delivered: DeliveryAmount;
  failed: DeliveryAmount;
  read: DeliveryAmount;
  sent: DeliveryAmount;
  undelivered: DeliveryAmount;
}

/**
 * Contains aggregated information about delivery statuses of a message across all participants
 * of a conversation.
 *
 * At any moment during the message delivery to a participant, the message can have zero or more of following
 * delivery statuses:
 * * Message is considered as **sent** to a participant if the nearest upstream carrier accepted the message.
 * * Message is considered as **delivered** to a participant if Twilio has received confirmation of message
 * delivery from the upstream carrier, and, where available, the destination handset.
 * * Message considered as **undelivered** to a participant if Twilio has received a delivery receipt
 * indicating that the message was not delivered. This can happen for many reasons including carrier content
 * filtering and the availability of the destination handset.
 * * Message considered as **read** by a participant if the message has been delivered and opened by the
 * recipient in a conversation. The recipient must have enabled the read receipts.
 * * Message considered as **failed** to be delivered to a participant if the message could not be sent.
 * This can happen for various reasons including queue overflows, account suspensions and media
 * errors (in the case of MMS for instance).
 *
 * {@link AggregatedDeliveryReceipt} class contains an aggregated value {@link DeliveryAmount} for each delivery status.
 */
class AggregatedDeliveryReceipt {

  private state: AggregatedDeliveryDescriptor;

  /**
   * @internal
   */
  constructor(data: AggregatedDeliveryDescriptor) {
    this.state = data;
  }

  /**
   * Maximum number of delivery events expected for the message.
   */
  public get total(): number {
    return this.state.total;
  }

  /**
   * Message is considered as **sent** to a participant if the nearest upstream carrier accepted the message.
   *
   * @return Amount of participants that have the **sent** delivery status for the message.
   */
  public get sent(): DeliveryAmount {
    return this.state.sent;
  }

  /**
   * Message is considered as **delivered** to a participant if Twilio has received confirmation of message
   * delivery from the upstream carrier, and, where available, the destination handset.
   *
   * @return Amount of participants that have the **delivered** delivery status for the message.
   */
  public get delivered(): DeliveryAmount {
    return this.state.delivered;
  }

  /**
   * Message is considered as **read** by a participant, if the message has been delivered and opened by the
   * recipient in a conversation. The recipient must have enabled the read receipts.
   *
   * @return Amount of participants that have the **read** delivery status for the message.
   */
  public get read(): DeliveryAmount {
    return this.state.read;
  }

  /**
   * Message is considered as **undelivered** to a participant if Twilio has received a delivery receipt
   * indicating that the message was not delivered. This can happen for many reasons including carrier content
   * filtering and the availability of the destination handset.
   *
   * @return Ammount of participants that have the **undelivered** delivery status for the message.
   */
  public get undelivered(): DeliveryAmount {
    return this.state.undelivered;
  }

  /**
   * Message is considered as **failed** to be delivered to a participant if the message could not be sent.
   * This can happen for various reasons including queue overflows, account suspensions and media
   * errors (in the case of MMS for instance). Twilio does not charge you for failed messages.
   *
   * @return Amount of participants that have the **failed** delivery status for the message.
   */
  public get failed(): DeliveryAmount {
    return this.state.failed;
  }

  _update(data: AggregatedDeliveryDescriptor): void {
    this.state = data;
  }

  _isEquals(data: AggregatedDeliveryDescriptor): boolean {
    const isTotalSame = this.total === data.total;
    const isSentSame = this.sent === data.sent;
    const isDeliveredSame = this.delivered === data.delivered;
    const isReadSame = this.read === data.read;
    const isUndeliveredSame = this.undelivered === data.undelivered;
    const isFailedSame = this.failed === data.failed;

    return isTotalSame && isSentSame && isDeliveredSame && isReadSame && isUndeliveredSame && isFailedSame;
  }
}

export {
  AggregatedDeliveryReceipt,
  AggregatedDeliveryDescriptor,
  DeliveryAmount,
};
