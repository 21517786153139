import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {SendImageSourceEvent} from '../events';

@customElement('cresta-lightbox')
export class CrestaLightbox extends LitElement {
  constructor() {
    super();
    window.addEventListener(SendImageSourceEvent.eventName, (event: Event) => {
      this.src = (event as SendImageSourceEvent).detail.src;
    });
  }

  static override styles = css`
    .modal {
      display: block;
      position: fixed;
      z-index: 1;
      padding-top: 100px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.9);
    }

    .modal-content {
      margin: auto;
      display: block;
      width: 80%;
      max-width: 700px;
    }

    .modal-content,
    #caption {
      animation-name: zoom;
      animation-duration: 0.6s;
    }

    @keyframes zoom {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    .close {
      position: absolute;
      top: 15px;
      right: 35px;
      color: #f1f1f1;
      font-size: 40px;
      font-weight: bold;
      transition: 0.3s;
    }

    .close:hover,
    .close:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }

    @media only screen and (max-width: 700px) {
      .modal-content {
        width: 100%;
      }
    }
  `;

  /**
   * Image Src
   */
  @property({type: String})
  src = '';

  override render() {
    if (this.src) {
      return html`
        <div class="modal">
          <span @click=${this.closeSpanClicked} class="close">&times;</span>
          <img src=${this.src} class="modal-content" />
        </div>
      `;
    }
    return html`<div style="display: none;"></div>`;
  }

  closeSpanClicked() {
    this.src = '';
  }

  override attributeChangedCallback(
    name: string,
    oldVal: string,
    newVal: string
  ) {
    super.attributeChangedCallback(name, oldVal, newVal);
    this.requestUpdate();
  }
}
